@import "../colors.scss";

// Color classes based on color role (prefered classes)

.cl-icon-default {
    color: $cl-icon-default !important;
}

.cl-icon-active {
    color: $cl-icon-active !important;
}

.cl-icon-inactive {
    color: $cl-icon-inactive !important;
}

// Color classes based on color shade from figma

.cl-primary-80, .cl-primary {
    color: $cl-primary-80 !important;
}

.cl-neutral-100 {
    color: $cl-neutral-100 !important;
}

.cl-neutral-90 {
    color: $cl-neutral-90 !important;
}

.cl-neutral-80 {
    color: $cl-neutral-80 !important;
}

.cl-neutral-70 {
    color: $cl-neutral-70 !important;
}

.cl-neutral-60 {
    color: $cl-neutral-60 !important;
}

.cl-neutral-30 {
    color: $cl-neutral-30 !important;
}

.cl-neutral-20 {
    color: $cl-neutral-20 !important;
}

.cl-neutral-00 {
    color: $cl-neutral-00;
}
