@import "../grid.scss";
@import "../colors.scss";
@import "../overflow.scss";

// Extra classes that can be used on material components so they follow some of
// our designed form components.

// .row-toggle which turns slide-toggle into full-width wide form element that
// supports larger amounts of text in the label
.mat-slide-toggle.row-toggle {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;

    .mat-slide-toggle-content {
        white-space: normal;
        padding-right: 10%;
    }

    .mat-slide-toggle-label {
        justify-content: space-between;
    }
}

.mat-expansion-panel-header-title {
    align-items: center;

    .mat-expansion-panel-header-title-right {
        margin-left: auto;
        margin-right: -16px;
    }
}

// mat-select modifier which makes mat-select usable and nice even without
// form-field wrapper. Useful when the select should look more like a button
// than form input.
.mat-select.mat-select-bare {
    height: grid(3);

    .mat-select-trigger {
        padding: 0 grid(2);

        .mat-select-value-text {
            &,
            mat-select-trigger {
                display: flex;
                align-items: center;
            }
        }
    }

    mat-select-trigger {
        .icon:first-child {
            margin-right: grid(1);
        }
    }
}

// mat-menu-item modifier that is thinner than the default that is sometimes
// used in our designs
.mat-menu-item-thin.mat-menu-item {
    height: grid(4.5);
}

.mat-select-panel.mat-select-panel-bare {
    $height: grid(4);

    .mat-option {
        display: flex;
        align-items: center;
        height: $height;
        // Override material's 3em without the text overflowing
        line-height: 1.4;

        &.mat-selected:not(.mat-option-multiple) {
            background: none;
        }

        .mat-option-prefix {
            width: grid(4);
            display: flex;
            align-items: center;
        }

        &,
        .mat-option-text {
            display: flex;
            font-size: 14px;
            line-height: $height;
        }
    }
}

// Default icon color for all icon/matmenu buttons
.mat-button,
.mat-icon-button,
.mat-menu-item {

    .icon {
        width: grid(2.5);
        height: grid(2.5);
    }

    &:not([color]) {
        .icon {
            color: $cl-icon-default;
        }
    }
}

// New default styles for mat-menu content
.mat-menu-panel .mat-menu-content {
    .mat-menu-item {
        display: flex;
        align-items: center;

        .icon {
            color: $cl-icon-default;
            margin-right: grid(2);
        }

        &[disabled] {
            color: inherit;
            opacity: 0.4;
        }
    }
}

// New default styles for nav lists
.mat-nav-list .mat-list-item .mat-list-item-content {
    font-size: 14px;
    // For some firefox uses 500 even though I can't find where that style
    // comes from using the dev tools
    font-weight: 400;
}

.sk-transparent-backdrop-overlay {
    background-color: transparent;
}

.mat-select-panel {
    @include thin-scrollbar;
}

.mat-button, .mat-raised-button, .mat-flat-button, .mat-stroked-button {
  text-transform: capitalize !important;
}
