@import "skCommon/style";

.sk-panel-header {
    display: flex;
    align-items: center;
    height: grid(8);
    border-bottom: 1px solid $cl-divider;
    padding-left: grid(2);

    .sk-panel-header-icon {
        color: $cl-icon-default;
        margin-right: grid(1);
    }

    .sk-panel-header-text {
        flex-grow: 1;
    }
}

.sk-panel-content {
    flex-grow: 1;
    padding: grid(2);
}

.sk-panel-actions {
    margin-top: auto;
    padding: grid(2);
    display: flex;
    justify-content: flex-end;
}
