@mixin table-sizing($rowHeight, $fontSize) {
    .morph-table {
        font-size: $fontSize;

        .morph-table-row {
            min-height: $rowHeight;
        }

        .morph-table-body {
            .morph-table-row {
                height: $rowHeight;
            }
        }
    }
}

sk-simple-table, sk-heatmap {
    display: contents;

    @include table-sizing(48px, 14px);

    .card .card-content {
        padding: 0;
    }

    .morph-table {
        display: table;
    }

    .morph-table-head {
        display: table-header-group;
    }

    .morph-table-body {
        display: table-row-group;
    }

    .morph-table-row {
        display: table-row;
        border-bottom: 1px solid #E0E0E0;
    }

    .morph-table-cell {
        display: table-cell;
        overflow: hidden;
        padding: 0 4px;

        &.highlighted {
            padding: 0 8px;
            font-weight: 500;
        }
    }

    .morph-table-body {
        .morph-table-cell {
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .date-cell {
        min-width: 96px;
    }
}

sk-heatmap {
    .heatmap-note {
        font-size: 10px;
        text-align: right;
        line-height: 14px;
        color: #969696;
        padding-right: 8px;
    }
}

// TODO: use em for cell height etc. and let font-size be defined in card template
// definition instead
sk-heatmap {
    @include table-sizing(18px, 10px);

    @include mobile-only {
        @include table-sizing(24px, 12px);
    }
}
