sk-customer-management {
    table {
        border-collapse: collapse;

        td, th {
            padding: 8px;
            text-align: left;
        }

        td {
            border-bottom: 1px solid gray;
        }

        th {
            border-bottom: 2px solid gray;
        }

        .button-cell {
            cursor: pointer;
            text-align: center;
            vertical-align: center;

            .icon {
                width: 16px;
                height: 16px;
            }

            &.full {
                color: #607AF5;
                background: rgba(#607AF5, 0.2);
            }

            &.trial {
                color: #1AB190;
                background: rgba(#1AB190, 0.2);
            }

            &.expired-trial {
                color: #F4504C;
                background: rgba(#F4504C, 0.2);
            }

            &.none {
                color: #808080;
                background: rgba(#808080, 0.2);
            }
        }
    }
}

.mat-row:hover .mat-cell {
    border-color: currentColor;
}
