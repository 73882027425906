@import "./utils/card";

.card {
    display: flex;
    flex-direction: column;
    background: $cl-content-background;
    overflow: hidden;
    border: 1px solid $cl-divider;
    border-radius: 4px;

    .card-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;

        &.with-title {
            height: grid(6);
            border-bottom: 1px solid;
            border-color: rgba(black, 0.12);
        }

        .h6 {
            padding: 0;

            @include desktop-only {
                &:not(.multiline) {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .card-header-buttons {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            color: #969696;
            position: relative;
            z-index: 1;

            > .mat-icon-button,
            > .mat-button,
            > sk-dashboard-button,
            > .separator {
                &:not(:last-child) {
                    margin-left: 8px;
                }
            }

            &:empty {
                display: none;
            }

            .separator {
                height: grid(3);
                width: 0;
                border-left: 1px solid $cl-neutral-20;
            }
        }

        &.with-title .card-header-buttons {
            margin-right: -12px;
        }

        &:not(.with-title) .card-header-buttons {
            margin-left: auto;
            pointer-events: none;
            margin-top: 8px;
            margin-bottom: -16px;

            > * {
                pointer-events: auto;
            }

            .mat-icon-button {
                width: 32px;
                height: 32px;

                .icon {
                    width: 12px;
                    height: 12px;
                }
            }
        }
    }

    .card-thumbnail {
        height: 200px;
        background-size: cover;
        background-color: #e6e6e6;
    }

    .card-content {
        flex-grow: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        &.scrollable {
            overflow: auto;
            @include thin-scrollbar();

            &:focus {
                outline: none;
            }
        }

        .card-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:not(:first-child) {
                margin-top: 16px;
            }

            .card-footer-text {
                display: flex;
            }

            .card-footer-actions {
                display: flex;
            }
        }

        .card-description {
            margin-top: 8px;
            color: #525252;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.25px;
            flex-grow: 1;

            @include in-dark-accent() {
                color: rgba(#ffffff, 0.7);
            }
        }
    }

    @include card-content-padding(24px);

    @include each-accent() using ($color, $accent, $is-dark) {
        background: $color;

        @if $is-dark {
            color: white;

            .card-header {
                border-color: rgba(white, 0.12);
            }
        }
    }
}

.card-holder {
    $card-padding: 10px;

    display: flex;
    flex-wrap: wrap;

    .card-wrapper {
        width: 100%;
        margin-bottom: $card-padding;

        .card {
            height: 100%;
            width: 100%;
        }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

            @for $i from 2 to 4 {
                &.cards#{$infix}-#{$i} {
                    margin: 0 -#{$card-padding / 2};

                    .card-wrapper {
                        padding: 0 #{$card-padding / 2};
                        width: percentage(1 / $i);
                    }
                }
            }
        }
    }
}

.background {
    @include each-accent() using ($color, $accent, $is-dark) {
        background: $color;

        @if $is-dark {
            color: white;
        }
    }
}

.chart-legend {
    display: flex;
    justify-content: center;
    align-items: center;

    // Timeserie's color is expected to be set as color css property for that
    // legend item.
    .chart-legend-item {
        $item-height: 38px;

        display: flex;
        justify-content: center;
        align-items: center;
        height: $item-height;
        border-radius: #{$item-height / 2};
        border: 1px solid $cl-neutral-20;
        padding: 0 16px;
        text-align: left;

        &:not(:last-child) {
            margin-right: grid(1);
        }

        .chart-legend-indicator {
            border-radius: 100%;
            border: 1px solid currentColor;
            width: grid(1);
            height: grid(1);
            margin-right: grid(1.25);
        }

        &.visible .chart-legend-indicator {
            background-color: currentColor;
        }

        .chart-legend-actions {
            margin-right: grid(-1);
            margin-left: grid(0.25);
            color: $cl-neutral-60;
        }

        .chart-legend-text {
            font-size: 12px;
            line-height: 14px;

            .chart-legend-title {
                color: $cl-neutral-80;
            }

            .chart-legend-detail {
                margin-top: 2px;
                color: $cl-neutral-60;
            }
        }

        &.visible {
            .chart-legend-text {
                .chart-legend-title {
                    color: $cl-neutral;
                }

                .chart-legend-detail {
                    color: $cl-neutral-80;
                }
            }
        }
    }
}
