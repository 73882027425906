sk-docs {
    display: flex;
    margin: -24px;
    height: calc(100% + 48px);

    .mat-nav-list {
        padding: 24px 0 24px 12px;
        padding-left: 24px;
        box-shadow: 6px 0 12px #dfdfdf;
    }

    .docs-content {
        padding: 24px;
        flex-grow: 1;
        overflow: auto;
    }
}

sk-docs-item {

    .docs-item-header {
        display: flex;

        .docs-item-children & {
            &::before {
                content: "-";
                display: inline;
                margin-right: 8px;
            }
        }

        .docs-item-name {
            font-weight: 500;
            font-size: 16px;
            width: 30%;
        }

        .docs-item-type {
            font-style: italic;
            width: 70%;
        }

        .docs-item-tags {
            font-weight: 700;
            font-size: 12px;
            font-style: italic;

            > * {
                color: #adadad;

                &:not(:last-child) {
                    margin-right: 8px;
                }
            }

            .icon {
                width: 16px;
                height: 16px;
            }
        }
    }

    .docs-item-description {
        font-size: 14px;
        color: #525252;
        padding-left: 16px;

        .docs-item-comment {
            margin: 1em 0;
            white-space: pre;

            code {
                padding: 1px 4px;
                background: #e6e6e6;
                border-radius: 4px;
                border: 1px solid #c4c4c4;
                font-size: 12px;
                white-space: nowrap;
                word-break: keep-all;
            }
        }
    }

    .docs-item-children {
        padding-left: 24px;
    }

    .docs-group:hover {
        border-left-color: #696969;
    }

    .docs-group {
        border-left: 1px solid #dbdbdb;
        padding-left: 8px;
    }
}

.mat-tooltip.docs-tooltip {
    background: #fff;
    color: #3b3b3b;
    font-size: 12px;
    line-height: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-top: #4963E1 4px solid;
}
