$font-roboto: Roboto, sans-serif;

// Styles defined and used in figma

.body-1 {
    font-family: $font-roboto;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.44px;
}

.body-2 {
    font-family: $font-roboto;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.25px;
}

.caption-1 {
    font-family: $font-roboto;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $cl-neutral-80;
}

$font-size-small: 12px;
$font-size-mid: grid(1.75);
$font-size-large: 16px;
