.clean-data-content {

    h1, h2, h3, h4, h5, h6, b {
        font-weight: 500;
    }

    img {
        max-width: 90%;
        margin: auto;
        margin-top: 1em;
        max-height: 600px;
    }

    table {
        width: 100%;
        margin-top: 1em;
    }

    td, th {
        padding: 12px 20px;
        border: 1px solid white;
        text-align: left;
        vertical-align: top;
    }

    td {
        background: #F0F0F0;
        font-size: 1em;
        line-height: 16px;
        letter-spacing: 0.25px;
        color: #525252;
    }

    th, thead td {
        background: #E6E6E6;
        font-style: normal;
        font-weight: 500;
        font-size: 1em;
        line-height: 16px;
    }

    figure {
        margin: 0;
        text-align: center;

        figcaption {
            font-size: 1em;
            font-style: italic;
            color: #525252;
        }
    }

    .template-snippet {
        $main-color: #808080;
        $focus-color: #525252;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 16px 0;
        height: 100px;
        background-color: #f0f0f0;
        border: 1px solid $main-color;
        border-radius: 2px;

        &, input {
            color: $main-color;
            font-size: 1em;
        }

        input {
            margin-top: 8px;
            border: none;
            height: 28px;
            border-bottom: 1px solid $main-color;
            background: transparent;
            width: 80%;

            &:focus {
                outline: none;
                border-bottom: 2px solid $focus-color;
                color: $focus-color;
            }
        }
    }
}
