@import "../icons.scss";

.icon {
    fill: currentColor;
    width: map-get($icon-sizes, "s");
    height: map-get($icon-sizes, "s");
}

@each $size-name, $value in $icon-sizes {
    .icon-#{$size-name} {
        width: $value !important;
        height: $value !important;
    }
}
