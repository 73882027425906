/**
 * Resize slide toggle component. When only size is given, width is
 * automatically computed to keep original ratio
 */
@mixin mat-slide-toggle-size($size, $width: 1.5 * $size) {
    .mat-slide-toggle {
        height: $size;
        // Label has overflow: hidden, so we need to increase line height
        // so it's not cut-off
        line-height: $size * 1.5;
    }

    .mat-slide-toggle-bar {
        height: 0.7 * $size;
        width: $width;
    }

    .mat-slide-toggle-thumb {
        height: $size;
        width: $size;
    }

    .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
        transform: translate3d($width - $size, 0, 0) !important;
    }

    .mat-slide-toggle-thumb-container {
        width: $size + 2px;
        height: $size + 2px;
        top: -2px;
    }

    // Ripple should be ideally disabled on resized slide-toggle, because the
    // animation size cannot be changed
    .mat-slide-toggle-ripple {
        width: $size;
        height: $size;
        top: calc(50% - #{$size / 2});
        left: calc(50% - #{$size / 2});
    }
}

@mixin mat-tab-nav-bar-height($height) {
    ::ng-deep {
        .mat-tab-links, .mat-tab-link {
            height: $height;
        }
    }
}
