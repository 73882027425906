@import "../grid";

.gutter {
    padding-left: $gutter !important;
    padding-right: $gutter !important;
}

.no-gutter {
    margin-left: -$gutter !important;
    margin-right: -$gutter !important;
}

// Inspired by bootstraps spacing util, but based on our grid units, rather
// than 0.5 of rem which is what bootstrap uses.
@for $i from 1 to 12 {
    .mt-#{$i} {
        margin-top: grid($i) !important;
    }
    .mr-#{$i} {
        margin-right: grid($i) !important;
    }
    .mb-#{$i} {
        margin-bottom: grid($i) !important;
    }
    .ml-#{$i} {
        margin-left: grid($i) !important;
    }

    .mx-#{$i} {
        margin-left: grid($i) !important;
        margin-right: grid($i) !important;
    }
    .my-#{$i} {
        margin-top: grid($i) !important;
        margin-bottom: grid($i) !important;
    }

    .m-#{$i} {
        margin: grid($i) !important;
    }

    .pt-#{$i} {
        padding-top: grid($i) !important;
    }
    .pr-#{$i} {
        padding-right: grid($i) !important;
    }
    .pb-#{$i} {
        padding-bottom: grid($i) !important;
    }
    .pl-#{$i} {
        padding-left: grid($i) !important;
    }

    .px-#{$i} {
        padding-left: grid($i) !important;
        padding-right: grid($i) !important;
    }
    .py-#{$i} {
        padding-top: grid($i) !important;
        padding-bottom: grid($i) !important;
    }

    .p-#{$i} {
        padding: grid($i) !important;
    }
}
