sk-dashboard-view, sk-data-explorer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;

    ng-component {
        display: contents;
    }

    .loading-overlay {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;

        .loading-message {
            color: #808080;
            margin-left: 8px;
        }
    }
}

sk-dashboard-list {
    .logo {
        height: intrinsic;
    }

    .create-dashboard {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #c4c4c4;
        font-weight: 500;
        font-size: 20px;
        width: 100%;
        height: 100%;
        cursor: pointer;

        .create-dashboard-icon {
            border-radius: 100%;
            border: dashed 2px #c4c4c4;
            padding: 32px;
            margin: 32px 0;

            .icon {
                display: block;
                width: 64px;
                height: 64px;
            }
        }
    }
}

sk-dashboard-card {
    display: contents;

    .card {
        position: relative;

        .editable-button {
            position: absolute;
            top: 8px;
            right: 8px;
        }
    }

    .mat-chip-list {
        margin-top: 6px;

        .mat-chip {
            padding: 2px 6px;
            min-height: auto;
            line-height: 18px;
            height: auto;
            font-size: 12px;
            text-transform: capitalize;
        }
    }

    .card-date {
        min-width: grid(6);
    }
}

sk-dashbaord-view-nav {
    display: contents;
}

.dashboard-popup-panel {
    // Panel needs to be relative so the close button can be position outside
    // the scrollable element
    position: relative !important;
    transform: translateX(-50%);

    .mat-dialog-container {
        padding: 0;
        // Also defined as constant in linechart component
        width: 380px;
    }

    // For some reason close button causes scrollbar to appear during animations
    .ng-animating.mat-dialog-container {
        overflow: visible;
    }
}

.cdk-global-overlay-wrapper.active-global-overlay-wrapper {
    z-index: 101;
}

.cdk-global-overlay-wrapper.passive-global-overlay-wrapper {
    z-index: 100;
}

sk-expanded-card {
    width: 100%;
    height: 100%;
    display: flex;

    .card {
        flex-grow: 1;
    }
}

.cdk-overlay-pane.expanded-card-dialog .mat-dialog-container {
    padding: 0;
}
