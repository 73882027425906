// Apply style to make the scrollbar pretty in most webkit browsers
@mixin thin-scrollbar() {
    &::-webkit-scrollbar {
        width: 5px;
        background-color: darken(white, 10%);
        height: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: darken(white, 50%);
        border-radius: 2.5px;
    }
}

@mixin ellipsis() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
