$no-mat-form-field-width-100: true;

@import "skCommon/sass/material";

body {
    .mat-menu-item {
        display: flex;
        align-items: center;

        .icon {
            width: 16px;
            height: 16px;
            color: #969696;
            margin-right: 8px;
        }

        .mat-slide-toggle {
            pointer-events: none;
            margin-left: auto;
            padding-left: 16px;
        }

        &:hover {
            color: black;
        }
    }

    .mat-menu-item, .mat-button, .mat-raised-button {
        text-decoration: none;
    }

    .mat-icon-button {
        // Flex-center icons in mat-icon-buttons
        padding: 0;
        line-height: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        &.icon-button-small {
            width: 32px;
            height: 32px;

            .icon {
                width: grid(2);
                height: grid(2);
            }
        }

        &.icon-button-toggle {
            color: #969696;

            &.enabled {
                color: black;
                background-color: #f0f0f0;

                @include each-accent() using ($color, $accent, $is-dark) {
                    color: $color;
                    background-color: rgba($color, 0.06);
                }
            }
        }
    }

    .mat-menu-item {
        @include each-accent() using ($color, $accent, $is-dark) {
            color: $color;

            .icon {
                color: $color;
            }
        }
    }
}

// Allow theming of mat-slide-toggles using our color themes
.mat-slide-toggle {
    @include each-accent() using ($color, $accent, $is-dark) {
        &.mat-checked .mat-slide-toggle-bar {
            background-color: rgba($color, 0.54);
        }

        &.mat-checked .mat-slide-toggle-thumb {
            background-color: $color;
        }
    }
}

[mat-label] {
    @include in-dark-accent() {
        color: white;
    }
}

.mat-form-field.no-bottom-padding {
    .mat-form-field-wrapper {
        padding-bottom: 0;

        .mat-form-field-underline {
            bottom: 0;
        }
    }
}
