html, body {
    min-height: 100%;
    margin: 0;
}

p {
    margin: 0;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}
