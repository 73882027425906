* {
    box-sizing: border-box;
}

.icon {
    fill: currentColor;

    // Clock-wise rotation for arrows
    @each $angle in (90, 180, 270)  {
        &.rotate-#{$angle} {
            transform: rotate(#{$angle}deg);
            transform-origin: center center;
        }
    }

    &.small {
        width: 12px;
        height: 12px;
    }

    &.regular {
        width: 16px;
        height: 16px;
    }

    &.large {
        width: 24px;
        height: 24px;
    }
}

a {
    text-decoration: none;
    cursor: pointer;
    color: #324BC3;

    &:hover {
        color: #4963E1;

        &:not(.mat-button):not(.mat-icon-button):not(.mat-button-base):not(.mat-tab-link):not(.mat-list-item) {
            text-decoration: underline;
        }
    }

    &:active {
        color: #2036AB;
    }
}

button {
    cursor: pointer;
    // Reset the bootstrap button styles
    background: none;

    &, &:focus {
        border: none;
        outline: none;
    }
}
