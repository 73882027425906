sk-core-slides {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: auto;

    @include thin-scrollbar;

    .slides {
        position: relative;
        width: 0;
        flex-grow: 1;
        overflow: visible;
        transition: transform ease 700ms;

        @include media-breakpoint-up(md) {
            height: 100%;
        }

        @include mobile-only {
            // Override the translate when switching from desktop to phone version
            transform: none !important;
        }
    }

    .fake-scrollbar {
        display: none;
        width: 12px;
        height: 100%;

        @include media-breakpoint-up(md) {
            display: block;
            height: 100%;
        }

        .fake-scrollbar-thumb {
            background-color: darken(white, 50%);
            border-radius: 2.5px;
            transition: transform 700ms linear;
            margin-right: 5px;
        }
    }

    sk-slide-container {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        transition: opacity ease-in 550ms;

        @include media-breakpoint-up(md) {
            height: 100%;
        }

        .cdk-overlay-container {
            position: absolute;

            // Material hides empty containers => we can't use it to calculate
            // its bounding box
            &:empty {
                display: block;
            }
        }

        .scroll-msg {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            color: #adadad;

            @include mobile-only {
                display: none;
            }

            .icon {
                width: 16px;
                height: 16px;
                color: #dbdbdb;
            }
        }
    }
}

sk-insights .vertical-menu .core-slides-nav {
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    @include mobile-only {
        display: none;
    }

    .mat-icon-button {
        width: 32px;
        height: 32px;

        .nav-point {
            width: 8px;
            height: 8px;
            border-radius: 100%;
            background-color: #adadad;
        }

        .icon {
            width: 16px;
            height: 16px;
            color: #adadad;
        }

        &.active {
            background: #E1E5F9;

            .nav-point {
                background: #4963E1;
            }

            .icon {
                color: #4963E1;
            }
        }
    }
}
