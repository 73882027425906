sk-documents-list {
    .mat-list-base .mat-list-item .mat-list-item-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .sublabel {
        font-size: 0.72em;
        line-height: 1;
        color: #969696;
    }
}

sk-documents-edit {
    display: flex;
    flex-direction: column;
    height: 100%;
}

sk-documents-document {
    display: contents;

    .html-holder {
        display: flex;
        flex-direction: column;

        mat-dialog-container & {
            padding-bottom: 80px;
        }
    }
}
