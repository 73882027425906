/**
 * Overwrite default dialog content padding for both regular padding and
 * shrinkable padding.
 * Usage:
 * .card {
 *     @include card-content-padding(12px);
 * }
 */
@mixin card-content-padding($padding) {
    .card-header.with-title {
        padding: 0 $padding;
    }

    .card-content {
        padding: $padding;
    }
}
