// Currently only colors required by some old components are specified
// Before we start using those colors in new ones, we should define some good
// naming conventions that will be used across all apps.

$cl-primary: #4963E1;
$cl-primary-focused: #E1E5F9;
$cl-primary-80: #4963E1;
$cl-primary-70: #607AF5;
$cl-primary-50: #9AA9F5;
$cl-primary-20: #E1E5F9;
$cl-primary-10: #EFF1FA;

$cl-neutral: #000000;
$cl-neutral-100: #000000;
$cl-neutral-90: #525252;
$cl-neutral-80: #696969;
$cl-neutral-70: #808080;
$cl-neutral-60: #969696;
$cl-neutral-50: #ADADAD;
$cl-neutral-40: #C4C4C4;
$cl-neutral-30: #DBDBDB;
$cl-neutral-20: #E6E6E6;
$cl-neutral-10: #F0F0F0;
$cl-neutral-00: #FFFFFF;

$cl-red-10: #FCECEB;
$cl-red-20: #FCDDDC;
$cl-red-30: #F1D3D2;
$cl-red-40: #E9B5B4;
$cl-red-50: #FF8C8A;
$cl-red-60: #F4504C;
$cl-red-70: #E23734;
$cl-red-80: #B22927;
$cl-red-90: #9C1312;

$cl-dark-green-10: #E1F6F9;
$cl-dark-green-20: #CFEDF3;
$cl-dark-green-30: #C2E5EB;
$cl-dark-green-40: #A4D0D6;
$cl-dark-green-50: #70C5D2;
$cl-dark-green-60: #4FB6C6;
$cl-dark-green-70: #27A2B5;
$cl-dark-green-80: #1F8696;
$cl-dark-green-90: #106C7A;
$cl-dark-green-100: #0C4F5A;

$cl-green-10: #F0F6DB;
$cl-green-20: #E4EDC9;
$cl-green-30: #DAE2C1;
$cl-green-40: #C1C7AC;
$cl-green-50: #A7C256;
$cl-green-60: #8CAB30;
$cl-green-70: #7A9135;
$cl-green-80: #637D14;
$cl-green-90: #4E6509;
$cl-green-100: #37460C;

$cl-icon-default: $cl-neutral-70;
$cl-icon-active: $cl-primary-70;
$cl-icon-inactive: $cl-neutral-30;

$cl-text-disabled: $cl-neutral-100;
$cl-text-disabled: $cl-neutral-60;

$cl-content: $cl-neutral-100;
$cl-content-background: $cl-neutral-00;
$cl-hovered-content-background: $cl-neutral-10;

$cl-divider: $cl-neutral-20;

$cl-card-border: $cl-neutral-20;

$cl-error-content: $cl-red-80;
$cl-error-background: $cl-red-10;
