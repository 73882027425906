// Colors
// https://www.figma.com/file/djxk8KjPnW6s3sCN9kCdXu/Colors

$accents: (
    "primary": #4963E1,
    "mint": #1A9479,
    "wine": #DB2498,
    "red": #E23734,
    "pink": #EC286C,
    "green": #637D14,
);

$dark-accents: (
    "primary"
    "mint"
    "wine"
    "red"
    "pink"
    "green"
);

@each $accent, $color in $accents {
    $is-dark: index($dark-accents, $accent);

    .color-#{$accent} {
        color: $color;
    }

    .bg-#{$accent} {
        background-color: $color;

        @if $is-dark {
            color: white;
        }
    }
}

@each $accent, $color in $accents {
}

@mixin in-dark-accent() {
    @each $accent in $dark-accents {
        .accent-#{$accent} &, .bg-#{$accent} & {
            @content;
        }
    }
}

@mixin each-accent() {
    @each $accent, $color in $accents {
        $is-dark: index($dark-accents, $accent);

        &.accent-#{$accent} {
            @content($color, $accent, $is-dark);
        }
    }
}
