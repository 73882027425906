sk-popup-wrapper {

    .popup-header {
        .popup-handle, .popup-close {
            background: #808080;

            .active-global-overlay-wrapper & {
                background: #607AF5;
            }
        }

        .popup-close {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1000;
            transform: translate(50%, -50%);
            color: white;

            width: 24px;
            height: 24px;

            .icon {
                width: 10px;
                height: 10px;
                color: white;
            }
        }

        .popup-handle-buffer {
            position: relative;
            z-index: 1000;
            height: 4px;
            transform: scaleY(4);
            transform-origin: top;
            transition: transform 0.1s ease;

            &:hover {
                transform: scaleY(8);
            }

            .popup-handle {
                height: 1px;
            }
        }

    }

    // Little hack to accomodate card components inside the dialog
    .card {
        box-shadow: none;
        margin: 0;

        .card-header {
            font-size: 16px;
            height: 46px;
        }

        @include card-content-padding(16px);
    }
}

.dashboard-dialog-panel {
    .mat-dialog-container {
        padding: 0;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }
}

sk-dialog-wrapper {
    display: contents;
}
