@import "../";

// New styles defined in figma, class names corespond to the figma style names
// Those classes only affect sizing & style, not colors and margins - those
// should be handled by separate classes.

body {
    font-size: 14px;
    line-height: 20px;
}

a {
    color: $cl-primary-80;
    text-decoration: none;
}

.sk-headline {
    font-family: $font-roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 60px;
}

h1,
.sk-large-title {
    font-family: $font-roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 40px;
}

h2,
.sk-title {
    font-family: $font-roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
}

h3,
.sk-heading {
    font-family: $font-roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
}

h4 {
    font-family: $font-roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
}

caption,
.sk-caption,
// caption-1 was old name for this style - remove once all uses are migrated
.caption-1 {
    font-family: $font-roboto;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $cl-neutral-80;
}

.sk-overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}

// Old styles defined and used in figma components Filip introduced

.body-1 {
    font-family: $font-roboto;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.44px;
}

.body-2 {
    font-family: $font-roboto;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.25px;
}
