// Typography
// https://www.figma.com/file/UxQVnAzxLkZ7DMuNhOp0gC/Typography

@import url(https://fonts.googleapis.com/css?family=Roboto:400,500&subset=latin,latin-ext);

@mixin globalFontStyle() {
    font-family: Roboto, sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.44px;
    color: #3B3B3B;
}

body {
    background-color: white;
    @include globalFontStyle();
}

button {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 14px;
}

p {
    margin: 0 0 1em 0;
}

.text-small {
    font-size: 0.8em;
}

.text-smaller {
    font-size: 0.6em;
}

.text-large {
    font-size: 1.2em;
}

.text-larger {
    font-size: 1.4em;
}

.h1 {
    font-weight: 300;
    font-size: 96px;
    line-height: 112px;
    letter-spacing: -1.5px;
    color: #3B3B3B;

    @include in-dark-accent() {
        color: white;
    }
}

.h2 {
    font-weight: 300;
    font-size: 60px;
    line-height: 70px;
    color: #000000;

    @include in-dark-accent() {
        color: white;
    }
}

.h3 {
    font-weight: normal;
    font-size: 40px;
    line-height: 47px;
    letter-spacing: 0.25px;
    color: #000000;

    @include in-dark-accent() {
        color: white;
    }
}

.h4 {
    font-weight: normal;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.25px;
    color: #000000;

    @include in-dark-accent() {
        color: white;
    }
}

.h5 {
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #000000;

    @include in-dark-accent() {
        color: white;
    }
}

.h6 {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #000000;

    @include in-dark-accent() {
        color: white;
    }
}

.h7 {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.15px;
    color: #000000;

    @include in-dark-accent() {
        color: white;
    }
}

.subtitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: rgba(#000000, 0.77);

    @include in-dark-accent() {
        color: rgba(white, 0.77);
    }
}

.caption {
    font-weight: normal;
    font-size: 12px;
    line-height: #{16 / 12};
    color: rgba(#000000, 0.77);

    @include in-dark-accent() {
        color: rgba(white, 0.77);
    }
}

.caption-2 {
    font-weight: normal;
    font-size: 12px;
    line-height: #{16 / 12};
    letter-spacing: 3px;
    text-transform: uppercase;
    color: rgba(#000000, 0.77);

    @include in-dark-accent() {
        color: rgba(white, 0.77);
    }
}

.caption-3 {
    font-weight: 500;
    font-size: 12px;
    line-height: #{16 / 12};
    color: rgba(#000000, 0.77);

    @include in-dark-accent() {
        color: rgba(white, 0.77);
    }
}

.p {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.44px;
    color: #3B3B3B;

    @include in-dark-accent() {
        color: rgba(white, 0.77);
    }
}

.p-2 {
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: 0.25px;
    color: #3B3B3B;

    @include in-dark-accent() {
        color: rgba(white, 0.77);
    }
}

strong, .strong, th {
    font-weight: 500;
}

.vertical-text {
    writing-mode: vertical-rl;
    text-orientation: mixed;
}
