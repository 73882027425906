.d-flex {
    display: flex !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-between {
    justify-content: space-between !important;
}
