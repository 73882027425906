@import '~@angular/material/theming';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// custom color pallete
$cl-primary: #4963E1;
$cl-primary-light: #7A8FF9;
$cl-primary-dark: #2036AB;

$cl-accent: #1A9479;
$cl-accent-light: #33C4A5;
$cl-accent-dark: #0A4F40;

$light-primary-text: white;

$colors-primary: (
    100: $cl-primary-light,
    500: $cl-primary,
    700: $cl-primary-dark,
    contrast: (
        100: $dark-primary-text,
        500: $light-primary-text,
        700: $light-primary-text
    )
);

$colors-accent: (
    100: $cl-accent-light,
    500: $cl-accent,
    700: $cl-accent-dark,
    contrast: (
        100: $dark-primary-text,
        500: $dark-primary-text,
        700: $light-primary-text
    )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://www.google.com/design/spec/style/color.html
$palette-primary: mat-palette($colors-primary);
$palette-accent:  mat-palette($colors-accent);

// The warn palette is optional (defaults to red).
$palette-warn:  mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$sk-app-theme: mat-light-theme($palette-primary, $palette-accent, $palette-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($sk-app-theme);

.mat-button, .mat-raised-button, .mat-flat-button, .mat-stroked-button {
    text-transform: uppercase;
    justify-content: center;
}


.mat-card {
    .mat-list, .mat-nav-list, .mat-selection-list {
        margin-left: -24px;
        margin-right: -24px;

    }

    .mat-list, .mat-nav-list {
        .mat-list-item .mat-list-item-content {
            padding: 0 24px;
        }
    }

    .no-margin-header .mat-card-header-text {
        margin: 0;
    }
}

@media ($mat-xsmall) {
    .mat-card {
        .mat-list, .mat-nav-list {
            margin-left: -16px;
            margin-right: -16px;

            .mat-list-item .mat-list-item-content {
                padding: 0 16px;
            }
        }
    }
}

.mat-dialog-container .mat-dialog-actions {
    display: flex;
    justify-content: flex-end;
}

$no-mat-form-field-width-100: false !default;

@if $no-mat-form-field-width-100 != true {
    mat-form-field {
        width: 100%;
    }
}

// Add support for our +icon() icons in mat-icon-buttons
.mat-icon-button .mat-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

// We often need to open mat dialog without the default padding - this class
// can be used as a panelClass
.mat-dialog-no-padding .mat-dialog-container {
    padding: 0;
}
