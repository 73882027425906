.map-value-label {
    // Mapbox changes font style of its markers
    @include globalFontStyle();

    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    // Mapbox by default sets will-change to transform which makes sense but
    // for some reason breakes sub-pixel rendering of fonts in chrome ???
    will-change: unset;

    .icon {
        width: 20px;
        height: 20px;
        line-height: 20px;
        box-shadow: 0px 2px 6px rgba(73, 99, 225, 0.25);
        border-radius: 100%;
        text-align: center;
        font-weight: 500;
        font-size: 12px;
    }

    .map-value-label-text {
        padding: 2px 6px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;

        &.change-none {
            color: #808080;
        }

        &.change-negative {
            color: #1AB190;
        }

        &.change-positive {
            color: #F4504C;
        }
    }

    &.clickable {
        cursor: pointer;

        &:hover {
            background: #e6e6e6;
        }
    }
}
