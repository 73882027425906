@import "../../../node_modules/mapbox-gl/src/css/mapbox-gl.css";

@import "./maps/valueLabels";

sk-maps-map {
    display: contents;

    .card {
        position: relative;
        overflow: hidden;
        margin-bottom: 40px;
    }

    .map-container {
        min-height: grid(32);
        height: 50vh;

        canvas {
            outline: 0;
        }
    }
}
