sk-charts-line-chart {
    display: contents;

    .chart-wrapper {
        position: relative;
        width: 100%;
        flex-grow: 1;
        min-height: grid(36);
        height: 30vh;

        @include media-breakpoint-down(sm) {
            height: 240px;
        }

        .chart-base {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
        }

        sk-chart {
            display: block;
            position: relative;
            flex-grow: 1;
            overflow: hidden;

            canvas {
                position: absolute;
            }
        }

        sk-chart-ripples {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            pointer-events: none;
        }

        .chart-event-label {
            position: absolute;
            background: #fff;
            color: black;
            font-size: 12px;
            padding: 8px 12px;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
            transform: translateX(-50%);
            bottom: 60px;
        }

        .zoom-button {
            transform: scale(0);
            transform-origin: center center;
            transition: transform 0.1s ease;
            position: absolute;
            top: 4px;
            right: 4px;
            width: 24px;
            height: 24px;

            .icon {
                width: 10px;
                height: 10px;
            }
        }

        &:hover {
            .zoom-button {
                transform: scale(1);
            }
        }
    }

    .legend {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        .copyright-button {
            width: 12px;
            height: 12px;
            color: #808080;
            margin-left: 6px;
        }

        sk-toggle-chip {
            margin-top: grid(0.5);
        }

        sk-toggle-chip + sk-toggle-chip {
            margin-left: grid(1);
        }
    }

    .chart-tabs {
        display: flex;

        .chart-tab {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: grid(5);
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            text-transform: uppercase;
            border-bottom: 1px solid $cl-divider;
            color: #696969;
            cursor: pointer;
            margin-top: -2px;
            background-color: $cl-content-background;

            &.active {
                color: #4963E1;
                border-bottom: 2px solid $cl-primary;
            }

            .icon {
                margin-right: 8px;
                width: 16px;
                height: 16px;
            }

            @include in-dark-accent() {
                color: rgba(white, 0.7);
                border-bottom-color: rgba(white, 0.4);

                &.active {
                    color: white;
                    border-bottom-color: white;
                }
            }
        }
    }
}

sk-chart-ripples {
    .linear-ripple {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1px;
        transform-origin: center;
        opacity: 0;

        animation: linearRipple ease 1.7s;

        &.delayed {
            animation-delay: 0.3s;
        }
    }
}

sk-chart-text-tooltips {
    display: contents;

    canvas {
        position: absolute;
        top: 0;
        bottom: 0;
        pointer-events: none;
    }
}

.line-chart-period-select-panel {
    .mat-menu-content:not(:empty) {
        padding: 0;
    }

    .mat-menu-item {
        height: 32px;
    }
}

@keyframes linearRipple {
    0% {
        opacity: 0.35;
        transform: scaleX(0);
    }
    100% {
        opacity: 0;
        transform: scaleX(60);
    }
}
